// official
$blue: #2e3192;//#2c6ba4;//#2E3192;
$orange: #f37032;//#efa951;//#EB5C24;
$pinkofficial: #ec008c;//#d82f70;//#D5155E;
$red: #ed1c24;
$pink: #ef4060;
$lightblue: #00b5e9;
$greyblue: #6c7fb7;
$darkblue: #004170;

$black: #333;
$green: #169d8f;//#3fa94c;//#4ea270;//#3fa94c;
$dark-green: #135c37;
$yellow: #f8fb01;
$purple: #562e78;
$max-outer-width: 1350px;
$max-inner-width: 1250px;