body {
  margin: 0;
}

header {
  min-height: 65px;
}

.wrapper {
  padding: 15px 0 0 0;
}

.langselecta {
  transition: all 0.6s ease;
  position: fixed;
  top: 25px;
  width: 150px;
  z-index: 6;
  cursor: pointer;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  .lsb {
    width: 96px;
    margin-left: 2px;
    font-family: LSR;
    font-size: 0.7rem;
    line-height: 0.6rem;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
  }
  .sall {
    text-transform: uppercase;
    line-height: 1rem;
    width: 100%;
    top: 10px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .snl,
  .sfr,
  .sen,
  .sde {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    max-width: calc(25% - 18px);
    padding: 0 0 5px 0;
    margin: 5px 0px 0 0px;
  }
  .alang {
    border-bottom: 1px solid white;
  }
}

.header-block {
  transition: all 0.6s ease;
  z-index: 5;
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 90px;
  background-color: $orange;
  box-shadow: 0px 0px 0px 0px;

  .main-nav {
    padding: 25px 0 10px 0;
    display: block;
    opacity: 0;
    background-color: $orange;
    width: 100%;
    height: 100%;
    padding: 0;
    margin-top: 80px;
    background-color: rgba(255, 255, 255, 0.98);
    background-color: $darkblue;
    -webkit-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    -moz-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    -ms-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    -o-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }

  ul {
    margin: 0;
    display: none;
    width: 100%;
    margin: 0 auto !important;
    padding: 0;
    li {
      display: block;
      text-align: center;
      margin: 0;
      transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        li {
          transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
          text-align: center;
          margin: 0;
          padding: 0;
          line-height: 1.25rem;
          &:hover {
            background-color: white;
            a {
              color: $darkblue;
            }
          }
        }
      }
      a {
        color: white;
        padding: 10px 20px;
        font-size: 1rem;
        display: block;
      }
    }
  }
  .oml {
    display: none;
  }
}

.logo {
  transition: all 0.6s ease;
  margin-left: 25px;
  margin-top: 0;
  max-width: 300px;
  z-index: 14;
  top: 12px;
  position: fixed;
}

main {
  margin-top: 10px;
}

section {
  margin: 0 auto;
  text-align: center;
  padding: 35px 0;

  .sinner {
    width: $max-inner-width;
    margin: 0 auto;
    display: block;
    padding-bottom: 25px;
    transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }
  h2 {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 40px 0;
  }
  div {
    display: inline-block;
    vertical-align: top;
    width: calc(92%/3 - 4px);
  }

  &:before {
    content: '\f29c';
    display: block;
    color: $orange;
    font-family: FontAwesome;
    font-size: 2.25rem;
    margin-bottom: 30px;
    display: none !important;
  }


  h3 {
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-top: 20px;
    color: #333;
    font-family: LSR;
    font-weight: normal;
    &.firsth3 {
      margin-top: 0;//10px;
    }
  }
  p {
    margin-top: 0;
  }
  .sinner {
    max-width: 550px;
    width: calc(100%/3 - 104px);
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 40px;
    text-align: left;
    transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    &:nth-child(1) {
      //background-color: pink;
      margin-left: 60px;
    }
    &:nth-child(2) {
      //background-color: blue;
    }
    &:nth-child(3) {
      //background-color: yellow;
      margin-right: 60px;
    }
  }
  div {
    display: block;
    width: auto;
  }
  .c-block {
    padding: 0;
  }


}

.clientlist {
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  img {
    display: inline-block;
    margin: 0;
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    mix-blend-mode: multiply;

    &:hover {
      -webkit-filter: grayscale(0); /* Chrome, Safari, Opera */
    filter: grayscale(0);
    }
  }
}

.contactinfo {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 50px;
}

#contact {
  padding-bottom: 0;
  margin-bottom: 0;
}



.about {
  #about h2 {
    color: $darkblue !important;
  }
  .h3special {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 40px; //60px;
    margin-top: -20px;
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.services {
  &:before {
    content: '\f0f6';
    color: $blue;
  }
  .header-block,
  .main-nav {
    background-color: $blue;
  }
  .langselecta {
    //background-color: $pink;
  }
  #services h2 {
    color: $darkblue !important;
  }
}

#workflow {
  padding-bottom: 0;
  margin-bottom: 0;
  img {
    margin-top: 20px;
    width: 100%;
    display: block;
  }
}

.workflow {
  &:before {
    content: '\f0d0';
    color: $lightblue;
  }
  .header-block,
  .main-nav {
    background-color: $lightblue;
  }
  .langselecta {
    //background-color: $pink;
  }
  #workflow h2 {
    color: $darkblue !important;
  }
}

.clients {
  &:before {
    content: '\f275';
    color: $greyblue;
  }
  .header-block,
  .main-nav {
    background-color: $greyblue;
  }
  .langselecta {
    //background-color: $orange;
  }
  #clients h2 {
    color: $darkblue !important;
  }
  .clientlist {
    //padding:60px 0;
    margin-top: 0;//50px;
    img {
      max-width: 50%;
    }
  }
}

.contact {
  &:before {
    content: '\f05a';
    color: $red;
  }
  .header-block,
  .main-nav {
    background-color: $red;
  }

  #contact h2 {
    color: $darkblue !important;
    text-align: center !important;
  }
  .contactinfo {
    margin-bottom: 70px;
  }

  a {
    color: $darkblue;
  }
}

.thisisus {
  &:before {
    color: $pink;
  }
  #thisisus h2 {
    color: $darkblue !important;
  }
  .header-block {
    background-color: $pink;
  }
  a {
    color: black;
  }
  .intro {
    font-weight: bold;
  }
  .person {
    margin: 10px 0;
    display: block;
    a {
      color: black;
      text-decoration: none;
      font-family: LSR;
      font-size: 0.75rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}


#contact,
.langsel section {

  .sinner {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }
  .c-block {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

// hamburger menu
.hamburger {
  position: fixed;
  top: 30px;
  right: 35px;
  cursor: pointer;
  z-index: 66666;
}

.slice {
  background-color: white;
  height: 3px;
  width: 25px;
  margin: 5px;
  -webkit-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  /* Saf3.2+, Chrome */
  -moz-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  /* FF4+ */
  -ms-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  /* IE10? */
  -o-transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  /* Opera 10.5+ */
  transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

.menu-open {
  .oml {
    position: fixed;
    top: 20px;
    left: 30px;
    text-align: left;
  }
  .square {
    opacity: 0;
  }

  .header-block {
    .main-nav {
      opacity: 1;
      background-color: $darkblue;
      margin-top: 90px;
      transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    .firstul {
      display: block;
    }
  }
  .hamburger {
    &:before {
      opacity: 0;
    }
    .slice {
      //background-color:white;
    }
    .slice:nth-child(1) {
      transform: translateY(8px) rotate(225deg);
    }
    .slice:nth-child(2) {
      opacity: 0;
    }
    .slice:nth-child(3) {
      transform: translateY(-8px) rotate(-225deg);
    }
  }
  .descr {
    opacity: 0.8;
  }
}

.alang {
  text-decoration: underline;
}




.langsel0 {
  section {
    display: none;
  }
  .contact {
    display: block;
  }
}



.langsel1 {
  section {
    .sinner {
      max-width: 800px;
      width: calc(100% - 120px);
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0 40px;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
      h2 {
        text-align: center;
      }
      &:nth-child(1) {
        //background-color: pink;
        margin-left: 60px;
        margin-right: 60px;
      }
    }
  }
  .left,
  .right {
    display: inline-block;
    vertical-align: top;
    margin: 0 -4px 0 0;
    max-width: calc(50% - 24px);
    text-align: left;
    .firsth3 {
      margin-top: 40px;
    }
    h3:nth-child(1) {
      margin-top: 0;
    }
  }
  .left {
    margin-right: 20px;
  }
  .right {
    margin-left: 20px;
  }
}


.langsel2 {

  h2 {
    font-size: 2.5rem;
  }

  #contact , section {

    .sinner {
      max-width: 450px;
      width: calc(100%/2 - 124px);
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0 60px;
      text-align: left;
      transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }

    .contactinfo {
      text-align: left;
    }
  }

  #contact h2 {
    text-align: left !important;
  }
}

.langsel3 {
  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .h3special {
   font-size: 1.25rem;
    line-height: 1.5rem;
  }

  #contact .sinner {
    width: calc(100%/3 - 104px);
    margin:0 50px;
    .contactinfo,h2 {
      text-align: left !important;
    }
  }
}

.langsel4 {

  h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .h3special {
    font-size: 1.1rem;
    line-height: 1.25rem;
  }

  #contact,
  section {
    .sinner {
      max-width: 450px;
      width: calc(100%/4 - 124px);
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0 60px;
      text-align: left;
      transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
      &:nth-child(1) {
        //background-color: pink;
        //margin-left:60px;
      }
      &:nth-child(2) {
        //margin-right:60px;
      }
    }
  }

  #contact .sinner {
    margin:0 60px;
    .contactinfo,h2 {
      text-align: left !important;
    }
  }
}


.lighter {
  opacity: 0.25;
  transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

.quote {
  background-color: white;
  color: $darkblue;
  font-size: 1.5rem; //2rem;
  line-height: 1.75rem; //2.5rem;
  font-family: "adobe-garamond-pro", sans-serif;
  font-style: italic;
  position: relative;
  z-index: 2;
  padding: 10px 0;
  // padding-top:20px;
  &:before,
  &:after {
    content: '';
    display: block !important;
    background-image: url(../images/penstroke.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    height: 64px;
    width: 146px;
    margin: 0 auto;
  }

  &:after {
    margin-top: -10px;
  }

  .quotetxt {
    max-width: 950px;
    margin: 0 auto;
    z-index: 0;
    padding: 10px 10px 0 10px;
    position: relative;
  }
}

.illu {
  background-color: white;//#a4dff9;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 50px;
    width: 50px;
    left: 0;
    right: 0;
    top: -40px;
    text-align: center;
    margin: 0 auto;
    border-left: 4px solid $greyblue;
    border-bottom: 4px solid $greyblue;
    // border-bottom-left-radius: 5px;
    transform: rotate(-45deg);
    z-index: 4;
  }
  .illuholder {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 50px;
  }
  .illuimg {
    padding:50px 25px 25px 25px;
    img {
      max-width: 80%;//100%;
      margin:0 auto;
    }
  }
  h4 {
    font-family: LSR;
    //text-decoration: underline;
    margin: 0;
    font-size: 1rem;
    margin-bottom: 5px;
    // text-align: center;
  }
}

.illutxt {
  position: relative;
  display: inline-block;
  border: 2px solid #f47d63;
  background-color: rgba(244, 125, 99, 1);
  color: white;
  border-radius: 20px;
  padding: 10px 10px;
  // width: 220px;
  width: calc(100%/5 - 54px);
  font-size: 0.9rem;
  line-height: 1.2rem;
  text-align: left;
  font-family: LSR;
  top:0;
  margin:10px;
}

.il {
  // border-bottom-right-radius: 0;
  left: 7.5%;
  transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

.ir {
  // border-bottom-left-radius: 0;
  //right: 7.5%;
  transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

.il1 {
  left: 0%;
  top:-80px;
}

.il2 {
  left: 0;
  top:-100px;
}

.il3 {
  left:0;
  top:-20px;
}

.il4 {
  left: 0;
  top:-45px;
}

.il5 {
  left: 0;
  top:-20px;
}

.cbanner {
  position: fixed;
  bottom: 0;
  z-index: 66666;
  background-color: $darkblue;
  width: 100%;
  color: white;
  font-size: 0.8rem;
  text-align: center;
  // font-weight: bold;
  a {
    color: white;
    text-decoration: none;
  }

  .divider {
    width: 16px;
    background-image: url(../images/penstroke_white_tiny.png);
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: 0px 2px;
    margin:0 10px;
  }
}


#jobs {
  padding-bottom: 60px;
}

/* blog overview */
.blog-page.overview {
  h2 {
    color: $blue;
    font-family: LSDB;
    color: $darkblue;
    font-weight: 100;
    font-size: 2.75rem;
    line-height: 2.75rem;
    font-family: "adobe-garamond-pro", sans-serif;
    text-align: center;
  }

  h3 {
    font-weight: normal;
    padding:0;
    margin:0;
    a {
      text-decoration: none;
    }
  }
  a {
    color:$darkblue;
  }
  article {
    padding-top: 0;
    padding-bottom: 40px;
    p {
      padding: 0;
      margin:10px 0;
    }
  }
}
/* BLOG POST DETAIL */
.blog-page {

  article {
    padding: 50px 25px;
    max-width: 600px;
    margin: 0 auto;
    h1 {
      color: $darkblue;//#333;
      font-family: LSR;
      font-weight: normal;
      margin-top: 0;
    }
    p {
      margin:20px 0;
    }

    .blog-date {
      color:$darkblue;
      font-size: 0.8rem;
    }
  }
}

.blog-page.multiple {
  article, .side {
    display: inline-block;
    vertical-align: top;
  }
  article {
    max-width: calc(100%/3*2);
  }
  .side {
    padding:0 25px;
    max-width: calc(100%/3 - 108px);
    h3 {
      margin-top: 50px;
      color: $darkblue;//#333;
      font-family: LSR;
      font-weight: normal;
      font-size: 1.25rem;
    }

    a {
      color:$darkblue;
      display: block;
      margin-bottom: 10px;
      // text-decoration: none;
      line-height: 1rem;

    }

  }
}

.g-page {

  article {
    padding: 50px 25px;
    max-width: 900px;
    margin: 0 auto;
    h1, h2 {
      color: $darkblue;//#333;
      font-family: LSR;
      font-weight: normal;
      margin-top: 0;
    }
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
    h2 {
      padding-bottom: 0;
    }
    p {
      margin:20px 0;
    }
    h2 {
      font-size: 1rem;
    }

    li {
      margin-bottom: 10px;
    }
    a {
      color: $black;
    }
  }

}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  /* Retina-specific stuff here */
  .quote {
    &:before,
    &:after {
      background-image: url(../images/penstroke_blue@2.png);
      height: 64px;
      background-size: 50%;
    }
  }
}

@media all and (max-width: 965px) {
  .header-block {
    float: none;
    width: 100%;
    text-align: center;
  }
  .sec-nav ul {
    padding-left: 0;
    margin-top: 30px;
    li {
      margin: 0 10px;
    }
  }
  .main-nav {
    margin-top: 20px;
    ul {
      justify-content: space-around;
    }
    li {
      margin: 5px;
    }
  }

  .illutxt {
    width: calc(50% - 54px);
    min-height: 125px;
    top:0;
  }
}


@media all and (max-width: 800px) {
  .langselecta {
    display: none;
    margin-top: 80px;
  }
  .menu-open {
    .langselecta {
      display: block;
    }
  }
  .header-block {
    .hamburger {
      display: block;
      z-index: 99999;
      right: 15px;
    }
    ul li ul {
      flex-flow: column;
      padding: 80px 0 40px 0;
      li {
        width: 100%;
      }
    }
  }
  .langsel1 {
    section {
      .sinner {
        width: calc(100% - 40px);
        // display: block;
        padding: 0;
        margin: 0 20px;
        text-align: center;
        &:nth-child(1) {
          //background-color: pink;
          margin-left: 0;
          margin-right: 0;
        }
      }

      .left,.right {
        display: block;
        width: 100%;
        max-width: 100%;
        margin:0;
      }
    }
  }

  .illu .illuimg img {
    max-width: 100%;
  }

}

@media all and (max-width: 400px) {
  .logo {
    max-width: 220px;
    margin-top: 5px;
  }

  .illutxt {
    width: calc(100% - 54px);
    min-height: auto;
  }
}
