// bower:scss
// endbower

@import 'typo';
@import 'variables';
@import 'extras';


html{
  -webkit-font-smoothing: antialiased;
}

body {  
  font-family: LSR;
  font-size: 0.9rem;
  line-height: 1.25rem;
  font-weight: normal;  
}


img {
  max-width: 100%;
  height: auto;
}

.main-nav {
  a {
    color: black;
    text-decoration: none;
    font-size: 1.25rem;
    font-family: LSR;
  }
}


section {
  
  h2 {
    // padding-top: 10px;
    color: $blue;
    font-family: LSDB;

    color: #333 !important;
    font-weight: 100;
    font-size: 2.75rem;
    line-height: 2.75rem;
    font-family: "adobe-garamond-pro", sans-serif;
    // text-align: left;
    
  }

  h3 {
    font-family: LSDB;
    color: $pink;
    margin-bottom: 5px;
  }
   
  
}

.services, 
.clients,
.contact,
section {
  background-color: rgba(216,216,216,0.15);

  ul, li {
    list-style: none;
    padding:0;
    margin:0;
  }
}

.scrolloff {
  pointer-events: none;
}


@import 'layout';